import React, { useState, useEffect } from "react";
import "../../styles/Sidebar.css";

import Newfile from "./Newfile";
import SidebarItem from "./SidebarItem";

import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import StorageIcon from "@material-ui/icons/Storage";

import { Dimmer, Loader } from 'semantic-ui-react';

import { deleteAllData } from '../utility/apiUtils';


const Index = ({ docArr, uProfile, updateSavedFiles, setErrorModalOpen, setUnAuthorizedErrorModalOpen}) => {
  const [loading, setLoading] = useState(false); // Loading state
  
  const handleDeleteAll = () => {
    try {
      const confirmed = window.confirm('Are you sure you want to delete all data ?'); // Confirmation dialog
      if (confirmed) {
        setLoading(true); // Activate loader
        deleteAllData(uProfile)
        .then(response => {
          setLoading(false); // Deactivate loader
          updateSavedFiles([], "removeAll");
        })
        .catch(error => {
          setLoading(false); // Deactivate loader
          console.error('Error fetching saved files, Additional details:', error);
          if (error.message.includes('status: 403')) {
            setUnAuthorizedErrorModalOpen(true);
          } else if (error.message.includes('No response received from server')) {
            // TO DO : HANDLE ERROR PROPERLY
            setUnAuthorizedErrorModalOpen(true);
          } else {
            // TO DO : HANDLE ERROR PROPERLY
            setErrorModalOpen(true);
          }
        });
      }
    } catch (error) {
      // TO DO : Show error message
      setLoading(false); // Deactivate loader
      console.error('Error in deleteDataUsingId:', error.message);
    } 
  };

  return (
    <>
      <div className="sideBar">
        <Newfile docArr={docArr} uProfile={uProfile} updateSavedFiles={updateSavedFiles}/>

        <div className="sidebar__itemsContainer">
          <SidebarItem icon={(<InsertDriveFileIcon />)} label={'Saved Models'} selected={true} />
          {/* <SidebarItem arrow icon={(<ImportantDevicesIcon />)} label={'Computers'} /> */}
          {/* <SidebarItem icon={(<PeopleAltIcon />)} label={'Shared with me'} /> */}
          {/* <SidebarItem icon={(<QueryBuilderIcon />)} label={'Recent'} /> */}
          {/* <SidebarItem icon={(<StarBorderIcon />)} label={'Starred'} /> */}
          <hr/>
          {/* <SidebarItem icon={<DeleteOutlineIcon />} label={"Delete All Models"} onClick={handleDeleteAll} disabled={docArr.length === 0}/> */}
          {/* <hr/> */}

          {/* <SidebarItem icon={(<StorageIcon />)} label={'Storage'} /> */}
        </div>
      </div>

      {loading &&(
        <Dimmer active>
          <Loader size='large' content='Deleting...' />
        </Dimmer>
      )}
    </>
  );
};

export default Index;
