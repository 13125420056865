import "firebase/compat/auth";
import firebase from "firebase/compat/app";

const firebaseConfig = {
    apiKey: "AIzaSyBxnl-0b-KHkIQlVJlFmjTAStPr3aZQxq0",
    authDomain: "measureuptesting.firebaseapp.com",
    databaseURL: "https://measureuptesting.firebaseio.com",
    projectId: "measureuptesting",
    storageBucket: "measureuptesting.appspot.com",
    messagingSenderId: "908299681884",
    authPersistence: 'local'
};

firebase.initializeApp(firebaseConfig)
var firebaseApp = firebase
const auth = firebase.auth()
const provider = new firebase.auth.GoogleAuthProvider()

export { auth, provider, firebaseApp }